import React from 'react'
import './Intro.css'
import { MDBRow, MDBCol, MDBCardBody, MDBIcon, MDBMask, MDBView, MDBContainer } from 'mdbreact';
import Text from '../../components/Text';
import frame1 from '../../assets/Frame1.png'
import frame2 from '../../assets/Frame2.png';
import "./Intro.css";

const Intro = () => {

  const text = {
    headingText: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit est laborum.",
    textSection2: "Our app allows you to be in full control of the menu and ingredients.",
    textSection3: "Customer will scan the QR code of your menu and can place the order online adn easily from home.",
  }


  return (
    <div className="intro-div">
      <h2 className="intro-header font-weight-bold text-center">We create for you</h2>
      <br></br>
      <MDBContainer>
        <MDBRow>
          <MDBCol size="12" sm="12" md="6" lg="6" className="mb-4">
            <MDBView className="overlay rounded z-depth-2" waves>
              <img
                src={frame1}
                alt=""
                className="img-fluid"
              />
              <a href="#!">
                <MDBMask overlay="white-slight" />
              </a>
            </MDBView>
            <MDBCardBody className="pb-0">
              <a href="#!" className="href">
                <h5 className="font-weight-bold mt-2 mb-3">
                  <MDBIcon icon="eye" className="pr-2" />
                  Inventory
                </h5>
              </a>
              <Text classes="mx-auto" text={text.textSection2} ></Text>
            </MDBCardBody>
          </MDBCol>
          <MDBCol size="12" sm="12" md="6" lg="6" className="mb-4">
            <MDBView className="overlay rounded z-depth-2" waves>
              <img
                src={frame2}
                alt=""
                className="img-fluid"
              />
              <a href="#!">
                <MDBMask overlay="white-slight" />
              </a>
            </MDBView>
            <MDBCardBody className="pb-0">
              <a href="#!" className="href">
                <h5 className="font-weight-bold mt-2 mb-3">
                  <MDBIcon icon="broadcast-tower" className="pr-2" />
                  Online Order
                </h5>
              </a>
              <Text classes="mx-auto" text={text.textSection3} ></Text>
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  )
}

export default Intro
