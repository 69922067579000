import { MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdbreact'
import React from 'react'
import image from '../../assets/waiter2.png'
import SubtitleText from '../../components/SubtitleText'
import Text from '../../components/Text'
import './Features.css'

const Features = () => {

  const text = {
    headingText: "Grow your customer base with your own online platform. Take control of operations with LimeTray's dine-in software suite comprising of Cloud-POS, Inventory Management, Third-party Order Management & Kitchen Display System. Engage & retain customers with custom Loyalty Programs, Feedback System & CRM solution.",
    menuManagement: {
      title: "Menu Management",
      menuText: "The restaurant Admin staff are able to calculate the price of each dish and work out the profit margin per item through the menu management function. This way an analysis can be made to work out cost effectivity."
    },
    reports: {
      title: "Reports",
      reportText: "This ensures a consistent availability of data and removes the need to construct reports manually. All this data will help you make strategic decisions to enhance the efficiency of your company, including the type of data to be collected, the position of data, and the way it is presented, making it highly useful for businesses."
    },
    crossplatform: {
      title: "Cross Platform",
      crossplatformText: "This offers a user experience that is complete and uniform. The expectation of today's consumers is a consistent user experience across multiple platforms and cross platform creation enables just that."
    },
    roleManagement: {
      title: "Role Management",
      roleManagementText: "The ability to delegate roles to the various employees in the restaurant hierarchy: (e.g.: admin, cashier, waiter and couriers). This is done through assigning each member of staff their own login portal."
    },
  }


  return (
    <div id="features" className="features-div">
      <h2 className="why-header font-weight-bold text-center">Why is it so Great?</h2>
      <br></br>
      <MDBContainer>
        <MDBRow>
          <MDBCol md="4">
            <MDBRow className="mb-3">
              <MDBCol size="2">
                <MDBIcon
                  icon="chalkboard-teacher"
                  size="2x"
                  style={{ color: "#8CB45F" }}
                />
              </MDBCol>
              <MDBCol size="10">
                <SubtitleText text={text.menuManagement.title} />
                <Text text={text.menuManagement.menuText} ></Text>
              </MDBCol>
            </MDBRow>

            <MDBRow className="mb-3">
              <MDBCol size="2">
                <MDBIcon icon="align-justify" size="2x" style={{ color: "#8CB45F" }} />
              </MDBCol>
              <MDBCol size="10">
                <SubtitleText text={text.reports.title} />
                <Text text={text.reports.reportText}></Text>
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol md="4" className="text-name d-flex align-items-center">
            <img
              className="img-fluid"
              src={image}
              alt="Food"
            />
          </MDBCol>
          <MDBCol md="4">
            <MDBRow className="mb-3">
              <MDBCol size="2">
                <MDBIcon icon="hotdog" size="2x" style={{ color: "#8CB45F" }} />
              </MDBCol>
              <MDBCol size="10">
                <SubtitleText text={text.crossplatform.title} />
                <Text text={text.crossplatform.crossplatformText}></Text>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-3">
              <MDBCol size="2">
                <MDBIcon icon="users-cog" size="2x" style={{ color: "#8CB45F" }} />
              </MDBCol>
              <MDBCol size="10">
                <SubtitleText text={text.roleManagement.title} />
                <Text text={text.roleManagement.roleManagementText}></Text>
              </MDBCol>
            </MDBRow>

          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>

  )
}

export default Features

