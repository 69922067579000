import { MDBCol, MDBInput, MDBRow } from 'mdbreact'
import React, { useState } from 'react'
import firebase from './../firebase';
import Modal from 'react-bootstrap/Modal'
import "./ContactForm.css";

const ContactForm = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [contactNumber, setContactNumber] = useState()
  const [restoName, setRestoName] = useState('')
  const [restoAddress, setRestoAddress] = useState('')
  const [demoDate, setDemoDate] = useState('')
  const [demoTime, setDemoTime] = useState('')

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    firebase.firestore().collection("customers").add({ name, email, contactNumber, restoName, restoAddress, demoDate: Date(), demoTime, date: Date() })
      .then(() => {

        setName('')
        setEmail('')
        setContactNumber('')
        setRestoName('')
        setRestoAddress('')
        setDemoDate('')
        setDemoTime('')

      })

      handleShow();

      console.log('email',email)
      
      let json2 = {
      email: email
     }

    const requestOptions = {
      method: 'POST',
      mode: 'no-cors',
      headers: { 'Content-Type': 'application/json' },
      body:  JSON.stringify(json2)
  };
  const response = await fetch('https://us-central1-restaurant-27f85.cloudfunctions.net/sendMails', requestOptions);
  console.log(response);

    let json={
    contactNumber:"+917044342030",
    text:"Name: " + name + "\n" +"Phone: " + contactNumber + "\n" + "Email: " + email + "\n" + "Restaurant Name: " +restoName + "\n" + "Address: " + restoAddress + "\n" + "Demo Date: " +demoDate + "\n" + "Demo Time: " +demoTime 
}
  const requestOptions2 = {
    method: 'POST',
    mode:'no-cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(json)
};

const response2 = await fetch('https://us-central1-restaurant-27f85.cloudfunctions.net/sendMsg', requestOptions2);
console.log(response2);
 

}

  

  return (
    <div class="main">
      <form >
        <MDBRow>
          <MDBCol md="12" className="mt-1 mb-0">
            <MDBInput className="mt-o mb-0 font-weight-normal" size="md" prepend="Small"
              value={name}
              onChange={e => setName(e.target.value)}
              type="text"
              id="name"
              label="Name of Owner/ Proprietor/ Manager"
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="6" className="mt-o mb-0">

            <MDBInput size="md" prepend="Small"
              value={email}
              onChange={e => setEmail(e.target.value)}
              type="text"
              id="email"
              label="Your Email"
            />

          </MDBCol>
          <MDBCol md="6" className="mt-o mb-0">

            <MDBInput size="md" prepend="Small"
              value={contactNumber}
              onChange={e => setContactNumber(e.target.value)}
              type="text"
              id="contact-number"
              label="Mobile No."
            />

          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="12" className="mt-o mb-0">

            <MDBInput size="md" prepend="Small"
              value={restoName}
              onChange={e => setRestoName(e.target.value)}
              type="text"
              id="restaurant-name"
              label="Restaurant Name"
            />
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="12" className="mt-o mb-0">
            <div className="md-form mb-0">

              <MDBInput size="md" prepend="Small"
                value={restoAddress}
                onChange={e => setRestoAddress(e.target.value)}
                type="text"
                id="RestoAddress"
                label="Restaurant Address "
              />
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="6" className="mt-o mb-0">
            <div className="md-form mb-0 ">
              <p className="dark-grey-text font-weight-light">Please Select Demo Date</p>
              <MDBInput size="sm" prepend="Small"
                value={demoDate}
                onChange={e => setDemoDate(e.target.value)}
                type="date"
                id="DemoDate"
              />
            </div>
          </MDBCol>
          <MDBCol md="6" className="mt-o mb-0">

            <div className="md-form mb-0">
              <p className="dark-grey-text font-weight-light">Please Select Demo Time</p>
              <MDBInput size="sm" prepend="Small"
                value={demoTime}
                onChange={e => setDemoTime(e.target.value)}
                type="time"
                id="DemoTime"
              />
            </div>
          </MDBCol>
        </MDBRow>
      </form>
      <div className="text-center">
        <button class="btn btn-video" onClick={formSubmitHandler}  type="submit" color="success" size="md">
        Request your free trial today
        </button>
      </div>

      <Modal  show={show} onHide={handleClose} data-backdrop="static"
        data-keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title className="eathubtitle font-weight-bold">EatHub</Modal.Title>
        </Modal.Header>
        <Modal.Body className="eathubtext font-weight-normal">Your free trial is on its way. You will soon be contacted about the next steps. Stay Safe</Modal.Body>
        <Modal.Footer>
          <button className="btn btn-modal" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ContactForm
