import React from 'react';
import Banner from '../../sections/Banner/Banner';
import Contact from '../../sections/Contact/Contact';
import Features from '../../sections/Features/Features';
import CustomFooter from '../../sections/FooterSection/CustomFooter';
import Intro from '../../sections/Intro/Intro';
import Operations from '../../sections/Operations/Operations';
import BlogSection from '../../sections/BlogSection/BlogSection';
import Testimonials from '../../sections/Testimonials/Testimonials';

const Home = () => {
  return (
    <div>
      <Banner />
      <Intro />
      <Operations />
      <Features />
      <BlogSection />
      <Testimonials />
      <Contact />
      <CustomFooter /> 
    </div>
  )
}

export default Home
