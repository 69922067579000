import React from 'react';
import { MDBContainer, MDBIcon, MDBMask } from "mdbreact";
import { useHistory } from 'react-router';
const TermsConditions = () => {
    const history = useHistory();
    return (
        <MDBMask>
        <br></br>
            <a><MDBIcon icon="arrow-left" size="lg" className="black-text ml-3"  onClick={() => history.push("/")} /></a>
            <MDBContainer>
                
                <div>
                    <h2 className="h1-responsive text-center font-weight-bold">Terms and Conditions</h2>
                    <p>THIS IS AN AGREEMENT BETWEEN YOU OR THE ENTITY THAT YOU REPRESENT (hereinafter “You” or “Your”) AND BIGBANG SOFTWARE PVT LTD . The Online service that is provided here would be EATHUB.<br></br>
                        <br></br>
                        <li>Parts of this Agreement</li><br></br>
This Agreement consists of the following terms and conditions (hereinafter the “General Terms”) and terms and conditions, if any, specific to use of individual Services (hereinafter the “Service Specific Terms”). The General Terms and Service Specific Terms are collectively referred to as the “Terms”. In the event of a conflict between the General Terms and Service Specific Terms, the Service Specific Terms shall prevail.<br></br>
                        <br></br>
                        <li>Acceptance of the Terms</li><br></br>
You must be of legal age to enter into a binding agreement in order to accept the Terms. If you do not agree to the General Terms, do not use any of our Services. If you agree to the General Terms and do not agree to any Service Specific Terms, do not use the corresponding Service. You can accept the Terms by checking a checkbox or clicking on a button indicating your acceptance of the terms or by actually using the Services.<br></br>
                        <br></br>
                        <li>Description of Service</li><br></br>

We provide a service that helps in billing, customer data management and monitoring operations of your business(“Service” or “Services”). You may use the Services for your personal and business use or for internal business purpose in the organization that you represent. You may connect to the Services using any Internet browser supported by the Services. You are responsible for obtaining access to the Internet and the equipment necessary to use the Services. You can create and edit content with your user account and if you choose to do so, you can publish and share such content.<br></br>
                        <br></br>

                        <li>User Sign up Obligations</li><br></br>

You need to sign up for a user account by providing all required information in order to access or use the Services. If you represent an organization and wish to use the Services for corporate internal use, we recommend that you, and all other users from your organization, sign up for user accounts by providing your corporate contact information. In particular, we recommend that you use your corporate email address. You agree to: a) provide true, accurate, current and complete information about yourself as prompted by the sign up process; and b) maintain and promptly update the information provided during sign up to keep it true, accurate, current, and complete. If you provide any information that is untrue, inaccurate, outdated, or incomplete, or if BigBang has reasonable grounds to suspect that such information is untrue, inaccurate, outdated, or incomplete, BigBang may terminate your user account and refuse current or future use of any or all of the Services.<br></br>

                        <br></br>
                        <li>Communications from BigBang</li><br></br>

The Service may include certain communications from BigBang, such as service announcements, administrative messages and newsletters. You understand that these communications shall be considered part of using the Services. As part of our policy to provide you total privacy, we also provide you the option of opting out from receiving newsletters from us. However, you will not be able to opt-out from receiving service announcements and administrative messages.
Complaints<br></br>
If we receive a complaint from any person against you with respect to your activities as part of use of the Services, we will forward the complaint to the primary email address of your user account. You must respond to the complainant directly within 10 days of receiving the complaint forwarded by us and copy BigBang in the communication. If you do not respond to the complainant within 10 days from the date of our email to you, we may disclose your name and contact information to the complainant for enabling the complainant to take legal action against you. You understand that your failure to respond to the forwarded complaint within the 10 days’ time limit will be construed as your consent to disclosure of your name and contact information by LogBase to the complainant.<br></br>
                        <br></br>
Free Trial And Fees<br></br>
                        <br></br>
                        <li>You may start using our App with a free trial. The free trial period of the App lasts for 3 weeks, or as otherwise specified during sign-up and is intended to allow you to try the service. You will be notified during sign-up whether you are eligible for a free trial.</li>
                        <br></br>
                        <li>We will bill your Payment Method for your yearly membership fee at the end of the free trial period unless you uninstall the App prior to the end of the free trial period. You will not receive a notice from us that your paid membership has begun.</li>
                        <br></br>
                        <li>You may cancel your membership at any time. However, we do not provide refunds or credits for partial membership periods.</li>
                        <br></br>
                        <li>We may change our service plans and the price of our Services from time to time.</li>
                        <br></br>

Eathub Privacy Policy
<br></br>
                        <br></br>
                        <li>We take your privacy very seriously. Eathub will only use your personal information in accordance with the terms of our privacy policy. By using the App you acknowledge and agree that you have read and accept the terms of our privacy policy and these Terms.</li><br></br>

Disclaimer / Liability
<br></br>
                        <br></br>
                        <li>USE OF THE APP IS AT YOUR OWN RISK. THE APP IS PROVIDED ON AN “AS IS” BASIS. TO THE MAXIMUM EXTENT PERMITTED BY LAW:<br></br>
                            <br></br> (A) eathub DISCLAIMS ALL LIABILITY WHATSOEVER, WHETHER ARISING IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE IN RELATION TO THE APP; AND <br></br>
                            <br></br>(B) ALL IMPLIED WARRANTIES, TERMS AND CONDITIONS RELATING TO THE APP (WHETHER IMPLIED BY STATUE, COMMON LAW OR OTHERWISE), INCLUDING (WITHOUT LIMITATION) ANY WARRANTY, TERM OR CONDITION AS TO ACCURACY, COMPLETENESS, SATISFACTORY QUALITY, PERFORMANCE, FITNESS FOR PURPOSE OR ANY SPECIAL PURPOSE, AVAILABILITY, NON INFRINGEMENT, INFORMATION ACCURACY, AS BETWEEN PETPOOJA AND YOU, ARE HEREBY EXCLUDED. IN PARTICULAR, BUT WITHOUT PREJUDICE TO THE FOREGOING, WE ACCEPT NO RESPONSIBILITY FOR ANY TECHNICAL FAILURE OF THE INTERNET AND/OR THE APP; OR ANY DAMAGE OR INJURY TO USERS OR THEIR EQUIPMENT AS A RESULT OF OR RELATING TO THEIR USE OF THE APP. YOUR STATUTORY RIGHTS ARE NOT AFFECTED.</li>
                        <br></br>
                        <br></br>
                        <li> Eathub will not be liable, in contract, tort (including, without limitation, negligence), under statute or otherwise, as a result of or in connection with the App, for any:</li>
                        <br></br>
1. economic loss (including, without limitation, loss of revenues, profits, contracts, business or anticipated savings); or<br>
                        </br>

2. loss of goodwill or reputation; or
<br></br>
3. special or indirect or consequential loss.
<br></br>
                        <br></br>
                        <li>IF Eathub IS LIABLE TO YOU DIRECTLY OR INDIRECTLY IN RELATION TO THE APP, THAT LIABILITY (HOWSOEVER ARISING) SHALL BE LIMITED TO tHE SUMS PAID BY YOU UPON PURCHASING THE APP, OR ANY IN-APP SPEND, INCLUDING SUBSCRIPTIONS.</li>
                        <br></br>
Service Suspension
<br></br>
                        <br></br>
                        <li>Eathub reserves the right to suspend or cease providing any services relating to the apps published by it, with or without notice, and shall have no liability or responsibility to you in any manner whatsoever if it chooses to do so.</li>
                        <br></br>
Advertisers In The App
<br></br>
                        <br></br>
                        <li>We accept no responsibility for advertisements contained within the App. If you agree to purchase goods and/or services from any third party who advertises in the App, you do so at your own risk. The advertiser, not Eathub , is responsible for such goods and/or services and if you have any queries or complaints in relation to them, your only recourse is against the advertiser.</li>
                    </p>
                </div>
            </MDBContainer>
        </MDBMask>
    )
}

export default TermsConditions
