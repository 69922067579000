import React, { useState } from 'react';
import {
  MDBCard, MDBCardBody, MDBCol,
  MDBContainer, MDBIframe, MDBModal, MDBModalBody, MDBModalHeader, MDBRow, MDBView, 
  
} from "mdbreact";
import "./Banner.css";
import logo from '../../assets/logo_eathub.png'
import cloud from '../../assets/cloud-kitchen.png'
import ghost from '../../assets/ghostkitchen.png'
import take from '../../assets/take-away.png'
import dine from '../../assets/dine-in.png'
import whatsapp from '../../assets/wa.png'
import ContactForm from '../../components/ContactForm';
import AdSense from 'react-adsense';
import { Nav, Navbar} from 'react-bootstrap';


const Banner = () => {

  const [collapse, setCollapse] = useState(false)
  const [modal, setmodal] = useState(false)

  const toggle = () => {
    setmodal(!modal)
  }

  return (
    <div id="home">

      <MDBView>
        <Navbar  expand="lg" className="z-depth-0">
          <div><img className="logo-img ml-0" src={logo} width="100" height="100"></img></div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" >
            <Nav className="ml-auto " >
              
             
              <Nav.Link className="anav mr-1 font-weight-normal" href="#features">Why EatHub</Nav.Link>
              <Nav.Link className="anav mr-1 font-weight-normal" href="#testimonials">Testimonial</Nav.Link>
              <Nav.Link className="anav mr-1 font-weight-normal" href="#about">About us</Nav.Link>
              <Nav.Link className="anav mr-1 font-weight-normal" href="#contact">Contact Us</Nav.Link>
              <Nav.Link className="anav mr-1 font-weight-normal" href="/Chat">Chat With Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <MDBContainer>
          <MDBRow>
            <MDBCol size="12" sm="12" md="6" lg="6">

              <h3 className="mt-xl-2 h2-responsive bannerTitle-text font-weight-bold ">
                Is the Pandemic affecting your Food Businesss? Not any more
              </h3>
              <hr />
              <p className="mb-4 font-weight-normal bannerText">
                Switch to takeaways & online orders with ease and gain more profits.
                We ensure smooth operation using our highly dependable end-to-end Restaurant
                Point-of-Sale System Manager especially for the Cloud/ Ghost Kitchens,
                 Takeaway Counters, Online Orders to help-scale up during the current scenario.</p>
              <p className="font-weight-normal bannerText">
                Watch this video to understand how EatHub helps in scaling up your Food Business.
              </p>

              <MDBRow>
                <MDBCol size="6" sm="6" md="6" lg="6" >
                  <button class="btn btn-video" onClick={toggle} >Video</button>
                </MDBCol>
                <MDBCol size="6" sm="6" md="6" lg="6" >
                  {/* <a class="btn btn-blog" href="https://blogeathub.blogspot.com/" >Blog</a> */}
                  <a class="btn btn-blog" href="https://eathub-blog.web.app/" >Blog</a>
                </MDBCol>
              </MDBRow>


              <MDBContainer className="grid">
                <MDBRow>
                  <MDBCol size="3" sm="3" md="3" lg="3" >
                    <div className="icon">
                      <img className="icon-img" src={cloud}></img>
                      <p className="font-weight-normal icon-para">Cloud kitchen</p>
                    </div>
                  </MDBCol>
                  <MDBCol size="3" sm="3" md="3" lg="3">
                    <div className="icon"><img className="icon-img" src={ghost}></img>
                      <p className="font-weight-normal icon-para">Ghost Kitchen</p></div>
                  </MDBCol>
                  <MDBCol size="3" sm="3" md="3" lg="3">
                    <div className="icon"><img className="icon-img" src={dine}></img>
                      <p className="font-weight-normal icon-para">Dine In</p></div>
                  </MDBCol>
                  <MDBCol size="3" sm="3" md="3" lg="3">
                    <div className="icon"><img className="icon-img" src={take}></img>
                      <p className="font-weight-normal icon-para">Takeaway</p></div>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
        
              <MDBModal size="lg" isOpen={modal} toggle={toggle}>
                <MDBModalHeader toggle={toggle}>Eathub Demo</MDBModalHeader>
                <MDBModalBody>
                  <MDBIframe src="https://www.youtube.com/embed/_420B6e-d-w" />
                </MDBModalBody>
              </MDBModal>
            </MDBCol>

            <MDBCol size="12" sm="12" md="6" lg="6" className="mt-xl-0 mb-2">

              <MDBCard className='dark-grey-text'>
                <MDBCardBody>
                  <h5 className='dark-grey-text text-center'>
                    <strong>Take a 20-day Free Trial</strong>
                  </h5>

                  <ContactForm />
                  <br></br>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>

      </MDBView>

      <a href="https://wa.me/message/ZNJ2KITYW3AKF1" className="wa">
        <img className="wa-img" src={whatsapp}></img>
      </a>
      <div className="res-div text-center ">
        <h3 className="header font-weight-bold">Restaurant POS System</h3>
        <p className="black-text  mb-5 text-center">
          Software technology is always evolving, and the best part about the restaurant industry
          is how it adapts to these changes and makes the most of them.
          Take the example of your restaurant POS system itself.
          Decades ago cash registers were fulfilling its purpose well,
          then came the first ever electronic point of sales system that could easily bill and get
          your orders to the kitchen. Cut to present – with cloud tech,
          POS software has become much more than a billing system,
          it’s an all-in-one technology platform for your restaurant’s needs.
        </p>
      </div>
    </div>
  )

}
<AdSense.Google
  client='ca-pub-9792429883102511'
  style={{ width: 500, height: 300, float: 'left' }}
  format=''
/>

export default Banner