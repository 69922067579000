import React from 'react'

const Text = ({text, classes}) => {
    return (
        <p className={`black-text w-responsive mb-5 ${classes}`}>
          {text}
        </p>
    )
}

export default Text
