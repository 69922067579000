import React, { useEffect, useRef, useState } from 'react';
import { MDBCol, MDBRow, MDBContainer, MDBView, MDBIcon } from 'mdbreact'
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import "./Chat.css";



const auth = firebase.auth();
const firestore = firebase.firestore();


function Chat() {
  const [user] = useAuthState(auth);

  return (
    <div className="App border-bg h-screen">
      <SignOut />
      <section className="flex justify-center items-center h-screen">
        {user ? <ChatRoom /> : <SignIn />}
      </section>
    </div>
  );
}


function SignIn() {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  }
  return (
    <div id="home" >
      <MDBView>
        <a href="/"><MDBIcon icon="arrow-left" size="lg" className="black-text ml-3 mt-3" /></a>
        <br></br><br></br>
        <MDBContainer className="mt-5">
          <MDBRow>
            <MDBCol size="12" sm="12" md="12" lg="6" className="text-center mx-auto mt-5">
              <div className="pt-5">
                <h2 style={{ color: "#ff7e00" }} className="chat font-weight-bold mt-5">Chat with Us</h2>
                <button className="btn btn-video mt-5" onClick={signInWithGoogle}>Sign In With Google</button>
              </div>

            </MDBCol>
          </MDBRow>

        </MDBContainer>
      </MDBView>
    </div>


  )
}

function SignOut() {
  return auth.currentUser && (
    <div className="w-full text-right">
      <button className="btn btn-exit export-button pr-4 pl-4 mr-4" onClick={() => auth.signOut()}>Sign Out</button>
    </div>
  )
}

function ChatRoom() {
  const dummy = useRef();
  const messagesRef = firestore.collection('messages');
  const query = messagesRef.orderBy('createdAt', 'asc').limitToLast(25);

  const [messages] = useCollectionData(query, { idField: 'id' });
  const [formValue, setFormValue] = useState('');

  const scrollToBottom = () => {
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(scrollToBottom, [messages]);

  const sendMessage = async (e) => {
    e.preventDefault();

    const { displayName, uid, photoURL } = auth.currentUser;

    await messagesRef.add({
      user: displayName,
      body: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid: uid,
      photoURL: photoURL
    })

    setFormValue('');
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div className="chat-bg w-full sm:w-2/3 p-2 rounded">
      <div className="overflow-y-auto h-screen-90">
        {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}
        <span ref={dummy}></span>
      </div>

      <form onSubmit={sendMessage} className="pt-3 pl-3 pr-3">
        <MDBRow>
          <MDBCol size="8" sm="8" md="9" lg="10">
            <div class="form-group basic-textarea">
              <textarea class="form-control pl-2 my-0" id="exampleFormControlTextarea2" rows="3" value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="Type your message here..."></textarea>
            </div>
            {/* <input className=" px-3 py-1 outline-none focus:shadow"   placeholder="Say something" /> */}
          </MDBCol>
          <MDBCol size="4" sm="4" md="3" lg="2">
            <button className={`btn btn-dark btn-rounded waves-effect waves-light float-right'}`} type="submit" disabled={!formValue}>Send</button>
          </MDBCol>
        </MDBRow>
      </form>
    </div>
  )
}

function ChatMessage(props) {
  const { user, body, uid, photoURL, createdAt } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'flex-row-reverse' : 'flex-row';
  const messageBodyClass = uid === auth.currentUser.uid ? 'sent-message-bg text-right orange lighten-4' : 'received-message-bg border pl-4';
  const imageClass = uid === auth.currentUser.uid ? 'ml-2' : 'mr-2';

  return (

    <div className={"p-3 flex no-wrap items-start ${messageClass}"}>
      {/* <div>
            <img className={'avatar  rounded-full object-cover w-10 ${imageClass}'} src={photoURL || 'https://i.imgur.com/rFbS5ms.png'} alt="{user}'s pfp" />
          </div> */}
      <div className={`block break-words pr-4 pt-2 pb-2 pt-2 rounded-md ${messageBodyClass}`}>
        <p className="text-xs">{user}</p>
        <p className="msg">{body}</p>
      </div>
    </div>
  )
}

export default Chat;
