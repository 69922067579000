import React, { useState } from "react";
import { MDBCard, MDBIcon } from "mdbreact";
import img1 from '../../assets/st.jpeg';
import img2 from '../../assets/td.jpeg';
import img3 from '../../assets/pc.png';
import img4 from '../../assets/food_house.png';
import img5 from '../../assets/krafty_kitchen_logo.png';
import "./Testimonials.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Testimonials = () => {

  const [index, setIndex] = useState(0);



  return (
    <div id="testimonials" className="test-div pt-4">

      <h2 className="test-header font-weight-bold text-center mt-4"> What Our Clients Have To Say</h2>
      <br></br>
      <Carousel arrows infinite>
        <div className="carousel-div">

          <MDBCard className="card z-depth-0 mb-5">
            <div className="text-center"><img className="testimg1" src={img1}></img></div>

            <p className="dark-grey-text mt-4 text-center">
              <MDBIcon icon="quote-left" className="pr-2" />
 We would like to express our satisfaction on the cooperation regarding the development of our POS
  SOFTWARE. The development team did a very professional job. W
 <MDBIcon icon="quote-right" className="pr-2" />
            </p>
            <hr />
            <h5 className="header font-weight-bold mb-2 text-center">Shreeja Kabra</h5>
            <h6 className="font-weight-semi-bold mb-2 text-center">Owner at Sweet Tooth </h6>

          </MDBCard>
          
        </div>
        <div>
          <MDBCard className="card z-depth-0">
            <div className="text-center"><img className="testimg1" src={img2}></img></div>
            <p className="dark-grey-text mt-4 text-center">
              <MDBIcon icon="quote-left" className="pr-2" />
        Under tight deadlines and with high expectations,
         EatHub was a pleasure to partner with for their POS SOFTWARE (EATHUB) for my restaurant management.  
      
        <MDBIcon icon="quote-right" className="pr-2" />
            </p>
            <hr />
            <h5 className="header font-weight-bold mb-2 text-center">Mahesh Singhania</h5>
            <h6 className="font-weight-semi-bold mb-2 text-center">Owner at Tram Deepo</h6>

          </MDBCard>
        </div>
        <div>
          <MDBCard className="card z-depth-0">
            <div className="text-center"><img className="testimg1" src={img3}></img></div>
            <p className="dark-grey-text mt-4 text-center">
              <MDBIcon icon="quote-left" className="pr-2" />
We have worked with EatHub to build a customize billing platform for us, the billing software we wanted them 
to build was based on User Interactive features and detail focus on Reports! They did fantastic job for us to
 deliver what we wanted!

<MDBIcon icon="quote-right" className="pr-2" />
            </p>
            <hr />
            <h5 className="header font-weight-bold mb-2 text-center">Mehul </h5>
            <h6 className="font-weight-semi-bold mb-2 text-center">Owner at Paan Casa</h6>

          </MDBCard>
        </div>
        <div>
          <MDBCard className="card z-depth-0">
            <div className="text-center"><img className="testimg2" src={img4}></img></div>
            <p className="dark-grey-text mt-4 text-center">
              <MDBIcon icon="quote-left" className="pr-2" />
              Eathub is the name we would recommend to every restaurant/cloud kitchen for a Pos software.
              they delivered great solutions for my overall restaurant management.
<MDBIcon icon="quote-right" className="pr-2" />
            </p>
            <hr />
            <h5 className="header font-weight-bold mb-2 text-center">Peter Salter </h5>
            <h6 className="font-weight-semi-bold mb-2 text-center">Owner at Food House</h6>

          </MDBCard>
        </div>

        <div>
          <MDBCard className="card z-depth-0">
            <div className="text-center"><img className="testimg3" src={img5}></img></div>
            <p className="dark-grey-text mt-4 text-center">
              <MDBIcon icon="quote-left" className="pr-2" />
              I would like to express my satisfaction on the cooperation regarding my POS Software for my restaurant.eathub and the development team did a very good job.
 I would like to recommend Eathub POS Software and the development team.
<MDBIcon icon="quote-right" className="pr-2" />
            </p>
            <hr />
            <h5 className="header font-weight-bold mb-2 text-center">Ulash Goenka </h5>
            <h6 className="font-weight-semi-bold mb-2 text-center">Owner at Krafty kitchen</h6>

          </MDBCard>
        </div>

      </Carousel>
    </div>
  );
}

export default Testimonials;