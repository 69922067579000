import { MDBCol, MDBContainer, MDBFooter, MDBRow } from 'mdbreact'
import React from 'react'
import './CustomFooter.css'
import logo from '../../assets/logo_eathub.png'

const CustomFooter = () => {
    return (
      <MDBFooter id="about" className="page-footer font-small lighten-5 pt-0">
      <div className="divf">
        <MDBContainer>
          <MDBRow className="py-4 d-flex align-items-center">
            <MDBCol md="6" lg="5" className="text-center text-md-left mb-4 mb-md-0">
              <h6 className="mb-0 white-text ">
                Get connected with us on social networks!
              </h6>
            </MDBCol>
            <MDBCol md="6" lg="7" className="text-center text-md-right">
              <a href="https://www.facebook.com/eathub.boon/" className="fb-ic ml-0 ">
                <i className="fab fa-facebook-f fa-lg white-text mr-md-5 mr-3 fa-2x"> </i>
              </a>
              <a href="https://www.linkedin.com/company/eathub-boon" className="li-ic">
                <i className="fab fa-linkedin-in fa-lg white-text mr-md-5 mr-3 fa-2x"> </i>
              </a>
              <a href="https://www.instagram.com/eathub.pos/" className="ins-ic">
                <i className="fab fa-instagram fa-lg white-text mr-md-5 mr-3 fa-2x"> </i>
              </a>
              
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
      <MDBContainer className="mt-5 mb-4 text-center text-md-left">
        <MDBRow className="mt-3">
          <MDBCol md="3" lg="5" xl="3" className="mb-4 dark-grey-text">
            <img src={logo} alt="Logo" className="logoFooter" />
          </MDBCol>
          <MDBCol md="2" lg="2" xl="2" className="mb-4 dark-grey-text">
            <h6 className="text-uppercase font-weight-bold">
              <strong>Products</strong>
            </h6>
            <hr className="green accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} />
            <p>
              <a href="#!" className="dark-grey-text">
                Billing
              </a>
            </p>
            <p>
              <a href="#!" className="dark-grey-text">
                CRM
              </a>
            </p>
            <p>
              <a href="#!" className="dark-grey-text">
                Stock & Inventory Management
              </a>
            </p>
            <p>
              <a href="#!" className="dark-grey-text">
                Report & Analytics
              </a>
            </p>
          </MDBCol>
          <MDBCol md="3" lg="2" xl="2" className="mb-4 dark-grey-text">
            <h6 className="text-uppercase font-weight-bold">
              <strong>Useful links</strong>
            </h6>
            <hr className="green accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} />
            <p>
              <a href="#about" className="dark-grey-text">
                About Us
              </a>
            </p>
            
            <p>
              <a href="#contact" className="dark-grey-text">
                Contact Me
              </a>
            </p>
            <p>
              <a href="https://wa.me/message/ZNJ2KITYW3AKF1" className="dark-grey-text">
                Help
              </a>
            </p>
            <p>
              <a href="/PrivacyPolicy" className="dark-grey-text">
                Privacy Policy
              </a>
            </p>
            <p>
              <a href="/TermsConditions" className="dark-grey-text">
                Terms and Conditions
              </a>
            </p>
          </MDBCol>
          <MDBCol md="4" lg="3" xl="3" className="mb-4 dark-grey-text">
            <h6 className="text-uppercase font-weight-bold">
              <strong>Contact</strong>
            </h6>
            <hr className="green accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} />
            <p>
              <i className="fa fa-home mr-3" /> FB 13, 1597 Rajdanga Main Road, TWP Kolkata - 700107
            </p>
            <p>
              <i className="fa fa-envelope mr-3" /> boonsoftware.eathub@gmail.com
            </p>
            <p>
              <i class="fas fa-phone-alt" aria-hidden="true" /> +91 62894 28802
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="divf">
        <MDBContainer>
          <MDBRow className="py-4 d-flex align-items-center">
            <MDBCol md="6" lg="6" className="text-center text-md-left mb-4 mb-md-0">
            <h5 className="mb-0 white-text ">
                GST Number - 19AAHCB4132E1ZY
              </h5>
            </MDBCol>
            <MDBCol md="6" lg="6" className="text-center text-md-right">
            <h5 className="mb-0 white-text ">
                CIN - U72900WB2017PTC222493
              </h5>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
      <div className="footer-copyright green lighten-5 text-center black-text py-3">
        <MDBContainer fluid >
          &copy; {new Date().getFullYear()} Copyright:{" "}
          <a  className="black-text" > Eat-Hub.com </a>
        </MDBContainer>
      </div>
    </MDBFooter>
    )
}

export default CustomFooter
