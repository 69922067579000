import { MDBCol, MDBIcon, MDBRow, MDBContainer } from 'mdbreact'
import React from 'react'
import './Operations.css'
import image from '../../assets/customer.png'
import image2 from '../../assets/food_tech2.png'
import Text from '../../components/Text'
import SubtitleText from '../../components/SubtitleText'


const Operations = () => {
  const text = {
    headingText: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit est laborum.",
    voiceCommand: "Our ingenious voice command feature for lightning speed execution in the restaurant management software and hands-free communication allows for multitasking on the go and a more modern approach to communication.",
    workOffline: "You no longer need to worry about unstable and glitchy internet connections stalling your operations with our handy offline operation function to avoid interruption in work-flow.",
    loyalty: "You have free reign to customise your own offers for diners which we apply through our handy app! Eliminate the redundant voucher codes as we favour the streamlined automatic applied offers at the checkout!",
    tableManagement: "This clever AI function will allow for tailored menus for each customer to create a unique dining experience. ",
    kitchenAssist: "This clever AI function will allow for tailored menus for each customer to create a unique dining experience. Customers can access menus depending on their age category, any special offers in place and depending on any special occasions or events.",
    moreSales: "Our platform ensures you connect to a wider audience to satisfy their insatiable appetites through our robust, far-reaching app.",
    onlineOrder: "Customers will scan the QR code of your menu and can place the order online and easily from home.",
  }
  return (
    <div id="ourfeatures" className="operations-div">
      <h2 className="op-header font-weight-bold text-center">Our Features</h2>
      <br></br>
      <MDBContainer>
        <MDBRow>
          <MDBCol size="12" sm="12" md="5" lg="5" className="mb-5 ">
            <img
              src={image}
              alt=""
              className="img-fluid"
            />
          </MDBCol>
          <MDBCol size="12" sm="12" md="7" lg="7" >
            <MDBRow className="mb-3">
              <MDBCol md="1" size="2">
                <MDBIcon fab icon="hubspot" size="2x" className="indigo-text" />
              </MDBCol>
              <MDBCol md="11" size="10">
                <SubtitleText text="AI Operated Voice Command" />
                <Text text={text.voiceCommand} ></Text>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-3">
              <MDBCol md="1" size="2">
                <MDBIcon icon="minus-circle" size="2x" className="red-text" />
              </MDBCol>
              <MDBCol md="11" size="10">
                <SubtitleText text="Work Offline" />
                <Text text={text.workOffline} ></Text>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-3">
              <MDBCol md="1" size="2">
                <MDBIcon icon="table" size="2x" className="blue-text" />
              </MDBCol>
              <MDBCol md="11" size="10">
                <SubtitleText text="Table Management" />
                <Text text={text.tableManagement} ></Text>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <hr className="my-5" />
        <MDBRow>
          <MDBCol size="12" sm="12" md="7" lg="7">
            <MDBRow className="mb-3">
              <MDBCol md="1" size="2">
                <MDBIcon icon="book" size="2x" className="cyan-text" />
              </MDBCol>
              <MDBCol md="11" size="10">
                <SubtitleText text="Customer Loyalty Scheme" />
                <Text text={text.loyalty} ></Text>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-3">
              <MDBCol md="1" size="2">
                <MDBIcon icon="layer-group" size="2x" className="red-text" />
              </MDBCol>
              <MDBCol md="11" size="10">
                <SubtitleText text="Simply More Sales" />
                <Text text={text.moreSales} ></Text>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-3">
              <MDBCol md="1" size="2">
                <MDBIcon icon="utensils" size="2x" className="deep-purple-text" />
              </MDBCol>
              <MDBCol md="11" size="10">
                <SubtitleText text="Kitchen Assist AI" />
                <Text text={text.kitchenAssist} ></Text>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-3">
              <MDBCol md="1" size="2">
                <MDBIcon icon="qrcode" size="2x" className="red-text" />
              </MDBCol>
              <MDBCol md="11" size="10">
                <SubtitleText text="Online Order" />
                <Text text={text.onlineOrder} ></Text>
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol size="12" sm="12" md="5" lg="5">
            <img
              src={image2}
              alt=""
              className="img-fluid"
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  )
}

export default Operations
