import React from 'react';
import { MDBContainer, MDBIcon, MDBMask } from "mdbreact";
import { useHistory } from 'react-router';


const PrivacyPolicy = () => {
    const history = useHistory();

    return (
        <MDBMask>
            <br></br>
            <a><MDBIcon  icon="arrow-left" size="lg" className="black-text ml-3" onClick={() => history.push("/")} /></a>
            <MDBContainer>
                
                <div className="policy-div">

                    <h2 className="h1-responsive text-center font-weight-bold">Privacy and Policy</h2>
                    <p >We respect the privacy of everyone who visits this website, uses the desktop application and/or uses our mobile applications (“App”). As a result we would like to inform you regarding the way we would use your personal data. We recommend you to read this privacy policy so that you understand our approach towards the use of your personal data.

                    By submitting your personal data to us, you will be treated as having given your permission, where necessary and appropriate.
                    Information we collect from you (“Information”)
<br></br>
                        <br></br>
                        <li>We collect your billing and your customer’s personal information when you use our services through our App.</li>
                        <li>We also collect information when you complete any customer survey.</li>
                        <br></br>
Use of information we collect from you<br></br>
                        <br></br>
                        <li>We may use the Information for data analytics, either for you or for 3rd parties.</li>
                        <li>We use this Information to provide support to you, communicate with you and comply with our legal obligations.</li>
                        <li>From time to time we may send the Information to third parties which we consider may have goods or services which are of interest to you. If you do not wish to be contacted by third parties please email us .</li>
                        <li>We may use the Information in any other manner that we may deem fit.</li>
                        <br></br>
Security of your information
<br></br>
                        <br></br>
                        <li>We have implemented appropriate physical, electronic, and managerial procedures to safeguard and help prevent unauthorized access to your information and to maintain data security. These safeguards take into account the sensitivity of the information that we collect, process and store and the current state of technology. We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it.</li>
                        <li>However, no method of transmission over the Internet or via mobile device, or method of electronic storage, is 100% secure. Therefore, we cannot guarantee its absolute security and thereby usage in a manner that is inconsistent with this Privacy Policy.</li>
                        <li>We assume no liability or responsibility for disclosure of your information due to errors in transmission, unauthorized third-party access, or other causes beyond our control. You play an important role in keeping your personal information secure. You should not share your user name, password, or other security information with anyone. If we receive instructions using your user name and password, we will consider that you have authorized the instructions.</li>
                        <br></br>
Third Party links
<br></br>
                        <br></br>
                        <li>The Services may contain links to third-party websites. Your use of these features may result in the collection, processing or sharing of information about you, depending on the feature. Please be aware that we are not responsible for the content or privacy practices of other websites or services which may be linked on our services.</li>
                        <li>We do not endorse or make any representations about third-party websites or services.</li>
                        <li>Our Privacy Policy does not cover the information you choose to provide to or that is collected by these third parties.</li>
                        <li>We strongly encourage you to read such third parties’ privacy policies.</li>
                        <br></br>
Changes to Privacy Policy<br></br>
                        <br></br>
                        <li>We reserve the right to amend this Privacy Policy from time to time to reflect changes in the law, our data collection and use practices, the features of our services, or advances in technology. Please check this page periodically for changes. Use of information we collect is subject to the Privacy Policy in effect at the time such information is used. If we make any material changes to this Privacy Policy, we will post the changes here. Please review the changes carefully. Your continued use of the Services following the posting of changes to this Privacy Policy will constitute your consent and acceptance of those changes.</li></p>
                </div>
            </MDBContainer>
        </MDBMask>
    )
}

export default PrivacyPolicy