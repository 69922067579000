import { BrowserRouter, Route, Switch} from 'react-router-dom';
import './App.css';
import { CustomerProvider } from './CustomerContext';
import Admin from './pages/Admin/Admin';
import Home from './pages/Home/Home';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsConditions from './sections/TermsConditions/TermsConditions';
import Varification from './sections/Varification/Varification';
import Chat from './pages/Chat/Chat';

function App() {
  
  return (
    <BrowserRouter>
    <CustomerProvider>
    <Switch>
    <div className="App">
      <Route exact path="/" component={Home} />
      <Route path="/Admin" component={Varification} />
      <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
      <Route path="/TermsConditions" component={TermsConditions} />
      <Route exact path="/Varifi/Admin" component={Admin} /> 
      <Route path="/Chat" component={Chat} />
    </div>
    </Switch>
    </CustomerProvider>
    </BrowserRouter>
  );
}

export default App;
