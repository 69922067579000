import React from 'react';
import "./Contact.css";

const Contact = () => {

    return (
        <div id="contact" className="contact-div pt-4">
          <h2 className="contact-header font-weight-bold text-center"> Contact us</h2>
     
          <p className="text-center  black-text text-center p-4">
            Attention fellow restaurant owners: our purpose is to deliver great value to your business! 
            We are here to tailor our service to meet your expectations. Our app is designed to provide 
            a unique restaurant experience, so we want to hear your feedback to provide an enhanced customisation, 
            so don’t hesitate to drop us a line.

            <button className="btn btn-cont"  size="md" align="center" href="#home">
              Contact Us
            </button>
          </p>
         
        
      
    </div>
    )
}

export default Contact
