import React,{createContext, useState, useEffect} from 'react'
import firebase from './firebase'

export const CustomerContext = createContext()

export const CustomerProvider = (props) => {

    const [customer, setCustomer] = useState([])

    useEffect(()=>{
        firebase.firestore().collection('customers')
        .onSnapshot((snapshot) => {
          const newCustomer = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
        }))
        setCustomer(newCustomer)
        })
      }, [])

    return (
        <CustomerContext.Provider value={[customer, setCustomer]} >
            {props.children}
        </CustomerContext.Provider>
    )
}
