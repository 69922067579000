import React, { useState } from 'react';
import { MDBContainer} from "mdbreact";
import { useHistory } from 'react-router';
import { MDBCol, MDBInput, MDBRow, MDBView } from 'mdbreact';
import "./varification.css";
import logo from '../../assets/logo_eathub.png';

const Varification = () => {
    const [name, setName] = useState('')
    const [pass, setpass] = useState('')
    const history = useHistory();
    const formSubmitHandler = (e) => {


        if (name == "EatHub" && pass == "boon@eatHub") {
            console.log(name, pass);
            history.push("/Varifi/Admin")
        }
        else {
            alert("Please Provide Exact User Name and Password");
        }
    }
    return (
        <div id="home">
            <MDBView>
             <div><img className="logo-img" src={logo} width="100" height="100"></img></div>
            
                <MDBContainer >
                    <form className="form-div">
                        <MDBRow className="mt-3">
                            <MDBCol md-6 mb-5 text-center>
                                <MDBInput
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                    type="text"
                                    id="name"
                                    label="User Name" />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md="12">
                                <MDBInput
                                    value={pass}
                                    onChange={e => setpass(e.target.value)}
                                    type="text"
                                    id="name"
                                    label="Password" />
                            </MDBCol>
                        </MDBRow>
                        <button className="btn btn-video" onClick={e => formSubmitHandler(e)}>Submit</button>
                    </form>
                </MDBContainer>
            </MDBView>
        </div>
    )
}

export default Varification
