import { MDBCard, MDBIcon, MDBView } from 'mdbreact'
import React, { useContext} from 'react'
import { CustomerContext } from '../../CustomerContext'
import "./Admin.css";



const Admin = (props) => {
  console.log(props);


  const [customer, setCustomer] = useContext(CustomerContext)

  console.log(customer)

  return (
    <MDBView>
      <a><MDBIcon icon="arrow-left" size="lg" className="black-text ml-3 mt-3 mb-5" /></a>
      <br></br>
      <div className="m-1 row boxlayout">

        {
          customer.map((c) => (
            <div className="card col-md-6 col-sm-12 z-depth-0">
              <MDBCard className="mb-2 p-3">

                <h5><b>{c.restoName}</b></h5>

                <p className="details">Name:  <b>{c.name}</b></p>
                <p className="others">Email:  <b>{c.email}</b></p>
                <p className="others">Contact Number:  <b>{c.contactNumber}</b></p>
                <p className="details">Restaurant Name: <b>{c.restoName}</b></p>
                <p className="others">Restaurant Address: <b>{c.restoAddress}</b></p>
                <p className="others">Demo Watching Date: <b>{c.demoDate}</b></p>
                <p className="others">Demo Request Time: <b>{c.demoTime}</b></p>
                <p className="others">Request Date: <b>{c.date}</b></p>

              </MDBCard>
            </div>
          ))
        }
      </div>

    </MDBView>
  )
}
export default Admin
