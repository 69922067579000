import React from "react";
import { MDBRow,MDBContainer, } from "mdbreact";
import SubtitleText from "../../components/SubtitleText";
import "./BlogSection.css";

const BlogSection = () => {

  const text = {
    headingText: "Grow your customer base with your own online platform. Take control of operations with LimeTray's dine-in software suite comprising of Cloud-POS, Inventory Management, Third-party Order Management & Kitchen Display System. Engage & retain customers with custom Loyalty Programs, Feedback System & CRM solution.",
    menuManagement: {
      title: "Menu Management",
      menuText: "The restaurant Admin staff are able to calculate the price of each dish and work out the profit margin per item through the menu management function. This way an analysis can be made to work out cost effectivity."
    },
    reports: {
      title: "Reports",
      reportText: "This ensures a consistent availability of data and removes the need to construct reports manually. All this data will help you make strategic decisions to enhance the efficiency of your company, including the type of data to be collected, the position of data, and the way it is presented, making it highly useful for businesses."
    },
    crossplatform: {
      title: "Cross Platform",
      crossplatformText: "This offers a user experience that is complete and uniform. The expectation of today's consumers is a consistent user experience across multiple platforms and cross platform creation enables just that."
    },
    roleManagement: {
      title: "Role Management",
      roleManagementText: "The ability to delegate roles to the various employees in the restaurant hierarchy: (e.g.: admin, cashier, waiter and couriers). This is done through assigning each member of staff their own login portal."
    },
  }



  return (
    <div id="about" className="blog-div">
      <h2 className="blog-header font-weight-bold text-center">About</h2>
      <SubtitleText />
      <MDBContainer>
        <MDBRow>
          <p className="black-text text-center">
            The restaurant and foodservice business is the largest business chain that connects to the customers daily. Covid 19 pandemic situation has impacted the industry, more than anything. As the saying goes, there’s light after the tunnel. Slowly, but surely food service business is set for a renaissance. Things won’t be the same as before the pandemic havoc. As result, the whole Food industry would undergo an evolution. We have to be ready and running, straight away. Some of the key features we provide are AI Operated Voice for hands-free communication which allows multitasking on the go, Work from Home, Table Management, Kitchen Assist AI, QR code ordering, QR code payment & Customer Loyalty Scheme.
            </p>
        </MDBRow>
      </MDBContainer>
    </div>

  );
};

export default BlogSection;
